<template>
  <div class="layout-wrapper">
    <TheHeader :only-logo="isBrand(CUSTOM_APPEARANCE.MBH)" />

    <div class="prelim-header has-text-centered">
      <p class="is-size-4 has-text-weight-bold mb-2 is-size-1-desktop">
        {{
          mortgage
            ? $t("af:mortgage_preliminary.title")
            : $t("af:preliminary.title")
        }}
      </p>
      <p class="has-text-weight-medium">
        {{
          mortgage
            ? $t("af:mortgage_preliminary.subtitle")
            : $t("af:preliminary.subtitle", { time: maxTime })
        }}
      </p>
    </div>

    <div class="af-container">
      <slot />
    </div>

    <div class="af-footer">
      <TheFooter />
    </div>

    <div class="navigator">
      <PreliminaryNavigator v-if="!mortgage" />
      <MortgagePreliminaryNavigator v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CUSTOM_APPEARANCE } from "@/utils/const";
import { isBrand } from "@/utils/util";
import MortgagePreliminaryNavigator from "@/components/preliminaryassessment/MortgagePreliminaryNavigator.vue";

export default {
  name: "AppLayoutPreliminaryAssessment",
  methods: { isBrand },
  components: {
    MortgagePreliminaryNavigator,
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
    PreliminaryNavigator: () =>
      import("@/components/preliminaryassessment/PreliminaryNavigator.vue"),
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    mortgage() {
      return this.$route.name === "MortgagePreliminaryAssessment";
    },
    ...mapGetters({
      maxTime: "preliminaryAssessment/maxTime",
    }),
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .prelim-header {
    background-image: url("../assets/images/background-sport-car.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 24px 6px;

    p {
      color: white;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .af-container {
    flex-grow: 1;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
  }

  .af-footer {
    // display: none;
  }
}

.mbh {
  .prelim-header {
    background-image: url("../assets/images/mbh_desktop_bg.png");
  }

  .af-container {
    max-width: 660px;
  }
}

@media only screen and (min-width: 769px) {
  .prelim-header {
    padding: 48px 0 !important;
  }

  .af-footer {
    width: 100%;
    display: flex !important;
  }
}

.navigator {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: white;
}
</style>
