<template>
  <div class="af-modal-card" :style="{ width: '500px' }" :class="brand">
    <div class="modal-card__content">
      <p class="has-text-weight-bold is-size-4 mb-4">
        {{ $t("af:offer_details.modal.acceptance.title") }}
      </p>

      <application-template
        template="OFFER_ACCEPTANCE_MODAL"
      ></application-template>

      <hr v-if="accConfigs && accConfigs.length > 0" />

      <statements-acceptance
        :statements="accConfigs"
        layout-type="CALC"
        read-required-validation-message="af:offer_details.modal.acceptance.message.validation.statement_read_required"
        ref="statementAcceptances"
        :is-public="false"
      />

      <hr v-if="accConfigs && accConfigs.length > 0" />
      <b-button
        id="offerAcceptanceModalButtonStart"
        type="is-blue"
        class="mt-4"
        @click="startApplication"
        :loading="isStarting"
        >{{
          $t("af:offer_details.modal.acceptance.button.start_application")
        }}</b-button
      >

      <div class="close-btn cursor-pointer" @click="cancelModal">
        <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APPLICATION_MODE, UI_CONFIGS } from "@/utils/const";
import i18n from "@/plugins/i18n";

export default {
  name: "OfferAcceptanceModal",
  components: {
    StatementsAcceptance: () => import("@/components/StatementsAcceptance.vue"),
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  props: {
    offerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      accConfigs: [],
      userId: null,
      isStarting: false,
    };
  },
  computed: {
    ...mapGetters({
      templateRender: "template/get",
    }),
    brand() {
      return this.$store.getters["config/getAutoloadConfigValue"](
        UI_CONFIGS.APPEARANCE
      );
    },
  },
  async created() {
    await this.$store.dispatch("acceptanceRequirements/fetchCalcConfig");
    this.accConfigs = this.$store.getters["acceptanceRequirements/calcConfig"];
    this.userId = this.$store.getters["session/userId"];
  },
  methods: {
    cancelModal() {
      this.$parent.close();
    },
    async startApplication() {
      let statementsValid = await this.$refs.statementAcceptances.validate();
      if (!statementsValid) return;

      this.isStarting = true;

      let assessmentId = this.$store.getters["offers/assessmentId"];

      try {
        await this.$store.dispatch("loanApplication/createApplication", {
          assessmentId: assessmentId,
          offerId: this.offerId,
        });

        await this.$store.dispatch("profile/updateStatus", {
          status: "IN_FLOW",
          result: "SUCCESS",
        });

        await this.$store.dispatch(
          "profile/updateApplicationMode",
          APPLICATION_MODE.LOAN
        );

        // Navigate to flow
        this.$router.push({ path: "/application" });

        this.track();

        this.cancelModal();
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:offer_details.modal.failed_start"),
          type: "is-danger",
        });
      } finally {
        this.isStarting = false;
      }
    },
    track() {
      this.$gtag.event("application_started");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.modal-card {
  background: white;
  border: 1px solid $grey-lightest;
  border-radius: 24px;
  position: relative;
  padding: 1rem;
  width: 100%;
  max-height: 90vh;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) {
  .modal-card {
    padding: 48px;
  }

  .statements {
    max-height: 12.5rem !important;
  }
}
</style>
